import { Component } from 'react';
import './custom.css'
import { LoyaltyOffer } from './components/LoyaltyOffer'
import { Message } from './components/Message';
import { LoyaltyOfferAdditionalMatches } from './components/LoyaltyOfferAdditionalMatches';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReceiptsForBusiness } from './components/ReceiptsForBusiness';
import { Tills } from './components/Tills';
import { Logon } from './components/Logon'
import { LoyaltyOffers } from './components/LoyaltyOffers'
import { Voucher } from './components/Voucher';
import { ChangePassword } from './components/ChangePassword';
import { ReviewRequestTemplate } from './components/ReviewRequestTemplate';



export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/message' element={<Message />} />
                    <Route path='/voucher' element={<Voucher />}/>
                    <Route path='/loyaltyoffer' element={<LoyaltyOffer />} />
                    <Route path='/receiptsforbusiness' element={<ReceiptsForBusiness />}/>
                    <Route path='/tills' element={<Tills />}/>
                    <Route path='/loyaltyoffers' element={<LoyaltyOffers />} />
                    <Route path='/logon' element={ <Logon />} />
                    <Route path='/changepassword' element={<ChangePassword />} />
                    <Route path='/reviewrequesttemplate' element={<ReviewRequestTemplate/> } />
                    <Route path='/' element={<LoyaltyOfferAdditionalMatches />}/>
                </Routes>
            </BrowserRouter>
        );
    }
}
