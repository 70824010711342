import React, { Component } from 'react'
import { isLoggedIn, fetchSignedMessage, getSessionValue, postAttachment, businessIdHeader, httpOKsuccess, postMethod, cssFormInputFile, cssFormInputLabel, fileFieldType, textField, cssFormInput, cssFormInputText, cssError, cssFormInputButton } from './Library'
import ShowHelp from '../img/help-icon.png'
import HideHelp from '../img/help-close-icon.png'
import { LoyaltyOffersProvider } from '../LoyaltyOffersContext';
import { NavMenu } from '../components/NavMenu';
import DualBallSpinner from '../img/dual-ball-1s-100px.gif';
import ReactDOM from 'react-dom';
import { format } from 'date-fns'


export class BusinessOption {
	constructor(key, value, text) {
		this.text = text;
		this.key = key;
		this.value = value;
	}
}

export class Message extends Component {

	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			title: '',
			description: '',
			attachmentPath: '',
			timeToSend: null,
			attachment: null,
			messageLink: '',
			htmlMessage: '',
			loading: true,
			errorDescription: '',
			errorTitle: '',
			helpVisible: false,
			sumbitButtonDisabled: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async componentDidMount() {
		await isLoggedIn();
		this.setState({ loading: false });
	}

	setDateControlToCurrentDateTime(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: data.value
		})
	}

	handleFileChange = (event) => {
		this.setState({
			attachment: event.target.files[0]
		})
	}


	handleSubmit = async (event) => {
		const isValid = this.handleValidation();
		if (isValid) {

			var responseLabel = document.getElementById("messageSentResponse");

			this.setState({ loading: true, sumbitButtonDisabled: true });
			ReactDOM.render('', responseLabel)
			const messageData = {
				title: this.state.title,
				description: this.state.description,
				businessid: getSessionValue(businessIdHeader),
				messageLink: this.state.messageLink
			};

			if (this.state.attachment != null) {
				this.state.attachmentPath = postAttachment(this.state.attachment);
				messageData.attachmentPath = 'https://' + process.env.REACT_APP_MESSAGE_BUCKET_NAME + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + this.state.attachmentPath;
			}

			if (this.state.timeToSend != null) {
				messageData.timeToSend = this.state.timeToSend;
			}

			if (this.state.htmlMessage != null) {
				messageData.htmlMessage = this.state.htmlMessage;
			}

			fetchSignedMessage(JSON.stringify(messageData), 'message', postMethod).then(response => {
				if (response.status == httpOKsuccess) {
					ReactDOM.render(<label className={cssFormInputLabel}>Message sent succesfully.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				} else {
					ReactDOM.render(<label className={cssFormInputLabel}>Message sending failed. Please try again later.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				}
			}).catch(error => {
				this.setState({ loading: false, sumbitButtonDisabled: false });
				ReactDOM.render(<label className={cssFormInputLabel}>Message sending failed. Please try again later.</label>, responseLabel)
			});
			
		}
		event.preventDefault();
	}

	finishLoading = () => {
		this.setState({ loading: false })
	}

	handleValidation() {
		let validated = true;

		if (!this.validateTextContainsText(this.state.description, "errorDescription", "A message requires a description")) {
			validated = false;
		}

		if (!this.validateTextContainsText(this.state.title, "errorTitle", "A message requires a title")) {
			validated = false;
		}

		return validated;
	}

	validateTextContainsText(valueToValidate, stateForErrorMessage, errorMessage) {
		if (valueToValidate.length == 0) {
			this.setState({ [stateForErrorMessage]: errorMessage });
			return false;
		} else {
			this.setState({ [stateForErrorMessage]: "" });
			return true;
		}
    }

	toggleHelp = (event) => {
		this.setState({
			helpVisible: !this.state.helpVisible
		});
	}

	render() {

		return (
			<LoyaltyOffersProvider>
				<NavMenu />
			<div>
					<form onSubmit={this.handleSubmit} className={ cssFormInput} >

						<label className={cssFormInputFile } >
						Title:
							<input type={textField} className={cssFormInputText} name="title" value={this.state.title} onChange={this.handleInputChange} />
						</label>{this.state.errorTitle && <p className={cssError} >{this.state.errorTitle}</p> }

						<label className={cssFormInputFile} >
						Description:
							<input type={textField} className={cssFormInputText } name="description" value={this.state.description} onChange={this.handleInputChange} />
						</label>{this.state.errorDescription && <p className={cssError } >{this.state.errorDescription}</p>}

						<label className={cssFormInputFile}>
							HTML Message:
							<input type={textField} className={cssFormInputText} name="htmlMessage" value={this.state.htmlMessage} onChange={this.handleInputChange} /><br />
						</label>

						<label className={cssFormInputFile} >
						Attachment:
							<input type={fileFieldType} name="attachment" onChange={this.handleFileChange} className={cssFormInputFile} /><br/>
						</label>

						<label className={cssFormInputFile}>
							Click through link:
							<input type={textField} className={cssFormInputText} name="messageLink" value={this.state.messageLink} onChange={this.handleInputChange}/><br />
						</label>

						<input type="submit" className={cssFormInputButton } value="Send Message" disabled={this.state.sumbitButtonDisabled} />
					<img src={this.state.helpVisible ? HideHelp : ShowHelp} className="btn-help" onClick={this.toggleHelp} />

				</form>
				<p id="help" className={"collapse" + (this.state.helpVisible ? ".in" : "")}>
					Use this form to send an in-app message to all your customers. The title will be the title of the message, the description the main body of the message and you can upload a file which the message will link to.</p>
				</div>
				{this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
				<div id="messageSentResponse" />
			</LoyaltyOffersProvider>
		);
	}
}
